@import './forms';

body > #root {
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
}

body {
    margin: 0;
    font-size: 13px;
    padding-right: 0 !important;
    overflow: hidden;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body > #root {
    display: flex;
    flex-direction: column;
}

*:focus {
    outline-color: #ffff33;
}


